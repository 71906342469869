import { MenuItem, NavMenuItemsList, TelessaudeServicesRoles } from '@/types';

import { IS_PRD } from '@/utils/EnvHelper/EnvHelper';
import Service0800ProfilesEnum from '@/modules/Service0800/utils/Enums/Service0800ProfilesEnum';

const { VITE_AUTH_WEB_URL, VITE_PLATAFORMA_LEGACY_URL } = import.meta.env;

const LobbyNavListItem: MenuItem = {
  type: 'action',
  icon: 'mdi-account',
  label: 'Trocar perfil',
  value: () => {
    if (confirm('Você será direcionado à seleção de perfil, ok?'))
      location.href = `${VITE_AUTH_WEB_URL}/`;
  },
};

const LogoutNavListItem: MenuItem = {
  type: 'action',
  icon: 'mdi-logout',
  label: 'Sair',
  value: () => {
    if (confirm('Você será deslogado, ok?'))
      location.href = `${VITE_PLATAFORMA_LEGACY_URL}/accounts/logout/`;
  },
};

const RecordsListItems: MenuItem = {
  type: 'route',
  icon: 'mdi-cogs',
  label: 'Cadastros',
  value: '',
  subItems: [
    {
      type: 'route',
      label: 'Bibliografias',
      value: 'bibliography.list',
      icon: '',
    },
    {
      type: 'route',
      label: 'Hipóteses',
      value: 'hypothesis.list',
      icon: '',
    },
  ],
};

const RemoteCareNavListItems: MenuItem = {
  type: 'route',
  icon: 'mdi-account-injury-outline',
  label: 'Consultas remotas',
  value: '',
  subItems: [
    {
      type: 'route',
      label: 'TeleNutri',
      value: 'remote-care.telenutri',
      icon: '',
    }
  ],
};

const HealthcareAssistanceNavListItems: MenuItem = {
  type: 'route',
  icon: 'mdi-message-question-outline',
  label: 'Consultorias',
  value: '',
  subItems: [
    {
      type: 'route',
      label: 'Consultoria ativa',
      value: 'healthcare-assistance.dynamic-prescription',
      icon: '',
    }
  ],
};

// Handle user available navigation menu items
const getRemoteCareNavItems = (userRoles: TelessaudeServicesRoles) => {
  if (userRoles.remotecare && userRoles.remotecare.length) return RemoteCareNavListItems;
  return false;
};

const getHealcareAssistanceNavItems = (userRoles: TelessaudeServicesRoles) => {
  const roles0800 = userRoles['0800'];
  if (roles0800.includes(Service0800ProfilesEnum.CONSULTOR)) return HealthcareAssistanceNavListItems;
  return false;
};

const getRecordsListItems = (userRoles: TelessaudeServicesRoles) => RecordsListItems;

export const getNavMenuList = (userRoles: TelessaudeServicesRoles | null): NavMenuItemsList => {
  const arrListItems: NavMenuItemsList = [];

  if (!userRoles) return arrListItems;

  const recordsNavItems = getRecordsListItems(userRoles);
  const remotecareNavItems = getRemoteCareNavItems(userRoles);
  const healthcareAssistanceNavItems = getHealcareAssistanceNavItems(userRoles);

  arrListItems.push(LobbyNavListItem);

  recordsNavItems && arrListItems.push(recordsNavItems);
  remotecareNavItems && arrListItems.push(remotecareNavItems);
  healthcareAssistanceNavItems && arrListItems.push(healthcareAssistanceNavItems);

  arrListItems.push(LogoutNavListItem);

  return arrListItems;
};
